import { Avatar, Box, Button, Checkbox, Container, CssBaseline, Fab, FormControlLabel, Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBar from "@mui/material/AppBar";
import theme from "../theme";
import './login.css'
import HomeIcon from '@mui/icons-material/Home';
import { Copyright } from "@mui/icons-material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginForm = () => {
const [data, setData]= useState({
  username:null,
  password:null
})
const handleChange=(e)=>{
   setData({
    ...data,
    [e.target.name]:e.target.value
   })
}
const history = useNavigate();
useEffect(()=>{
 const mamberID =  localStorage.getItem('mamberId');
   if(mamberID){
    history('/');     }
},[])
  const handleSubmit = async(event) => {
    event.preventDefault();
        const datas = new FormData();
        datas.append("username", data.username);
        datas.append("password", data.password);
   
        try {
            const response = await axios.post('https://utkrishtsociety.com/api/userapi.php?action=login', datas)
              .then((res)=>{
                if (res.data.message === "Success") {
                  localStorage.setItem('mamberId', data.username);
                  history('/'); 
                 
              } else{
                toast(res.data.message, {
                  className: 'custom-toast'
                });
              }

              })
             }catch (error){
              console.log("Error",error)
    }
    
  };

  return (
    <>
     <div id="app" data-v-app="" style={{ fontSize: "12px" }}>

        <Box sx={{ flexGrow: 1 }}>
        {/* <CssBaseline /> */}
              <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
               borderRadius:'8px', 
              padding: "2px 10px",
            }}
          >
            <img src="/images/logo10.png" width="70px" alt="" />
            <Typography variant="h6" style={{ marginLeft: '-40px'}} color={theme.palette.primary.light}>
            Utkrisht
            </Typography>
            <Link to="/">
              <Box
                style={{ borderColor: "#fff", color: "#fff" }}
              >
                <HomeIcon />
              </Box>
            </Link>
          </AppBar>
        </Box>
      </div> 

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary' }}>    
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" >
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Enter User Name"
              autoFocus
              name="username"
              placeholder="Enter username"
              type="text"
              id="username"
              onChange={handleChange}

            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              onChange={handleChange}
              name="password"
              placeholder="Enter your Password"
              type="password"
              id="password"

            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              
              variant="contained"
              sx={{ mt: 3, mb: 2, color:"#fff", fontSize:'18px' }}
            >
              Sign In
            </Button>
            <ToastContainer />

            <Grid container>
              <Grid item xs>
                <Link to="/forgetpass" variant="body2" style={{ textDecoration: 'none'}}>
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
          </Box>
          <Fab
          // color="primary"
          aria-label="add"
          style={{
            position: "fixed",
            
            
            right: "9px",
            bottom: "69px",
            borderRadius: "22px",
            color: "#fff",
          }}
          onClick={() => history("/help")}
        >
       <img src="/images/help.png" width="97px"/>
        </Fab>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </>
  );
};
export default LoginForm;
