import { Button, Typography } from "@mui/material";
import NavBar from "./Navbar";
import { useNavigate } from "react-router-dom";

const ForgetPass= ()=>{
    let navigate = useNavigate();
    return(
        <>
           <NavBar name="Forget Password" />
        <Typography style={{    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '324px',
    fontWeight: '700',
    fontSize: '24px',
    color: 'darkred',}}>Contact to Head Office </Typography>
        <Button
                fullWidth
                variant="contained"
                style={{
                  bgColor: "primary",
                  color: "#fff",
                  fontSize: "20px",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
        </>
    )
}
export default ForgetPass;