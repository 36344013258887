import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";

var items = [
  {
    url: "/images/das1.png",
  },
  {
    url: "/images/das2.png",
  },
  {
    url: "/images/das3.png",
  },
  {
    url: "/images/das4.png",
  },

];
const SliderComponent = () => {
  return (
    <Carousel autoPlay Fade indicators>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

export default SliderComponent;

function Item(props) {
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxHeight: { xs: 190, sm: 260, md: 290, lg: 353 } }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid style={{ width: "100%", marginTop: "10px", height: { xs: 190, sm: 260, md: 290, lg: 353 } }}>
          <img
            width="100%"
            height='200px'
            style={{ borderRadius: "10px" }}
            src={props.item.url}
          />
        </Grid>
      </Grid>
    </>
  );
}
