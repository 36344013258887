import { Paper, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const HomeCard = (props) => {
  return (
    <Paper
    elevation={5}
    style={{
      background: "white",
      minHeight: "80px",
      // minWidth: "80px",
      // minWidth: { xs: '80px', sm: '80px', md: '80px', lg: '80px' },
      // width:'80px',
      borderRadius: "4px",
    }}
    sx={{  minWidth: { xs: '80px', sm: '80px', md: '80px', lg: '80px' },
    }}
  >
    <Link
      to={props.link}
      style={{
        textDecoration: "none",
        display: "flex",
        color:'#000',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        alt="Image"
        src={props.src}
        width="60px"
        height="50px"
        style={{ padding: "5px" }}
      />
      <Typography>{props.name}</Typography>
    </Link>
  </Paper>
  )
}

export default HomeCard
