import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Paper } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
const FooterNav = () => {
  const [value, setValue] =useState(0);
  const id = localStorage.getItem('mamberId')
  const history = useNavigate();
  return (<>
     <Paper elevation={5} style={{position:'fixed',
      bottom:0, width:"100%",}} >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          history(newValue);
        }}
        style={{ gap:39, }}
      >
        <BottomNavigationAction  value="/" 
        style={{ maxWidth: "10px", borderRadius: "6px", color:'#39AFEA'}}
         label="Home" icon={<HomeIcon  sx={{ fontSize: 30 }}/>}
         sx={{ '& .MuiBottomNavigationAction-label': { fontSize: 14 } }} />

        <BottomNavigationAction value="#" style={{  maxWidth: "10px",borderRadius: "6px", color:'#39AFEA'}} 
        label="Wallet" icon={<AccountBalanceWalletIcon sx={{ fontSize: 30 }}/>}
        sx={{ '& .MuiBottomNavigationAction-label': { fontSize: 14 } }}  />
        {/* <BottomNavigationAction value="/dds" style={{  maxWidth: "10px",borderRadius: "6px", color:'#39AFEA'}} label="DDS" icon={<AccountBalanceIcon />} /> */}
        <BottomNavigationAction value={`/my_details/${id}`}
         style={{  maxWidth: "10px",borderRadius: "6px", color:'#39AFEA'}}
          label="My" icon={<PersonIcon sx={{ fontSize: 30 }}/>} 
          sx={{ '& .MuiBottomNavigationAction-label': { fontSize: 14 } }}  />
      </BottomNavigation>
    </Paper>

              </>
  );
};
export default FooterNav;
