import { Avatar, Box, Button, Fab, Paper, Stack, Typography } from "@mui/material";
import FooterNav from "./FooterNav";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "./Navbar";
import { styled } from "@mui/material/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LockResetIcon from "@mui/icons-material/LockReset";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SvgIcon from "@mui/material/SvgIcon";
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

const MyDetails = () => {
  const [data, setData] = useState();
  const [loding, setLoading] = useState(false);
  const proid = useParams();
  const history = useNavigate();
  //   not useeeeeeeeeeeeee
  const fetchData = async (proid) => {
    try {
      const response = await axios
        .get(
          `
            https://utkrishtsociety.com/api/me?action=getuserinfo&memberid=${proid}`
        )
        .then((res) => {
          setData(res.data.user_Data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData(proid.id);
  }, []);

  return (
    <div id="app" data-v-app="" style={{ fontSize: "12px" }}>
      <NavBar name="Details" />
      {loding === false ? (
        <Box
          sx={{
            width: "100%",
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {data.length > 0 ? (
            <div>
              {data.map((datas, ind) => {
                return (
                  <Paper
                    elevation={3}
                    key={ind}
                    style={{
                      borderRadius: 10,
                      margin: "10px",
                      padding: "10px",
                    }}
                  >
                    <Paper
                      style={{
                        border: "1px solid primary",
                        borderRadius: "17px",
                        margin: "15px 0",
                        height: "100px",
                        backgroundColor: "#fbf5ff",
                      }}
                    >
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={`/profile/${proid.id}`}
                      >
                        <Box
                          style={{
                            display: "flex",
                            height: "100px",
                            justifyContent: "space-between",
                            padding: "0 15px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src="/images/myimg.png"
                              sx={{ width: 56, height: 56 }}
                            />
                            <Box ml={1}>
                              <Typography
                                variant="body2"
                                // align="center"
                                color="primary"
                              >
                                {datas.txtcustomerName}
                              </Typography>
                              <Typography
                                fontSize="16px"
                                color="primary"
                                align="left"
                              >
                                {datas.txtMobile}
                              </Typography>
                            </Box>
                          </Box>

                          <KeyboardArrowRightIcon align="right" />
                        </Box>
                      </NavLink>
                    </Paper>
                    <Stack spacing={2}>
                      {/* <Item> */}

                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={`/account_detatils/${proid.id}`}
                      >
                        <Box
                          style={{
                            display: "flex",
                            // height: "100px",
                            justifyContent: "space-between",
                            padding: "0 15px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "15px",
                                // backgroundColor: "#fff1b7",
                              }}
                            >
                              <AccountBalanceIcon />
                            </Box>
                            <Box ml={1}>
                              <Button
                                color="primary"
                                style={{ fontSize: "18px", align: "left" }}
                              >
                                Saving Account
                              </Button>
                            </Box>
                          </Box>

                          <KeyboardArrowRightIcon align="right" />
                        </Box>
                      </NavLink>

                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={`/changepassword/${proid.id}`}
                      >
                        <Box
                          style={{
                            display: "flex",
                            // height: "100px",
                            justifyContent: "space-between",
                            padding: "0 15px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "15px",
                                // backgroundColor: ",
                              }}
                            >
                              <LockResetIcon />
                            </Box>
                            <Box ml={1}>
                              <Button
                                color="primary"
                                style={{ fontSize: "18px", align: "left" }}
                              >
                                CHANGE PASSWORD
                              </Button>
                            </Box>
                          </Box>

                          <KeyboardArrowRightIcon align="right" />
                        </Box>
                      </NavLink>

                      <NavLink
                        style={{ textDecoration: "none" }}
                        // to={`/account_detatils/${proid.id}`}
                      >
                        <Box
                          style={{
                            display: "flex",
                            // height: "100px",
                            justifyContent: "space-between",
                            padding: "0 15px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "15px",
                                // backgroundColor: "#fff1b7",
                              }}
                            >
                              <ReceiptLongIcon />
                            </Box>
                            <Box ml={1}>
                              <Button
                                color="primary"
                                style={{ fontSize: "18px", align: "left" }}
                              >
                                TRANSACTION
                              </Button>
                            </Box>
                          </Box>

                          <KeyboardArrowRightIcon align="right" />
                        </Box>
                      </NavLink>

                      {/* <NavLink
                        style={{ textDecoration: "none" }}
                        // to={`/account_detatils/${proid.id}`}
                      >
                        <Box
                          style={{
                            display: "flex",
                            // height: "100px",
                            justifyContent: "space-between",
                            padding: "0 15px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "15px",
                                // background Color: "#fff1b7",
                              }}
                            >
                              <AccountBalanceWalletIcon />
                            </Box>
                            <Box ml={1}>
                              <Button
                                color="primary"
                                style={{ fontSize: "18px", align: "left" }}
                              >
                                INCOME
                              </Button>
                            </Box>
                          </Box>

                          <KeyboardArrowRightIcon align="right" />
                        </Box>
                      </NavLink> */}
                      {/* <NavLink style={{ textDecoration: "none" }} to={`/help`}>
                        <Box
                          style={{
                            display: "flex",
                            // height: "100px",
                            justifyContent: "space-between",
                            padding: "0 9px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                borderRadius: "15px",
                                // background Color: "#fff1b7",
                              }}
                            >
                              <img src="/images/help.png" width="41px"/>
                            </Box>
                            <Box>
                              <Button
                                color="primary"
                                style={{ fontSize: "18px", align: "left" }}
                              >
                                Need Help
                              </Button>
                            </Box>
                          </Box>

                          <KeyboardArrowRightIcon align="right" />
                        </Box>
                      </NavLink> */}
                    </Stack>
                  </Paper>

                );
              })}
              <Fab
          // color="primary"
          aria-label="add"
          style={{
            position: "fixed",
            
            
            right: "9px",
            bottom: "69px",
            borderRadius: "22px",
            color: "#fff",
          }}
          onClick={() => history("/help")}
        >
       <img src="/images/help.png" width="97px"/>
        </Fab>
            </div>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                height: "200px",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "serif",
                  fontSize: "16px",
                  fontStyle: "italic",
                }}
              >
                Record Not Found
              </p>
            </Box>
          )}
        </>
      )}
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </div>
  );
};

export default MyDetails;

export const Profile = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);
  const proid = useParams();

  const fetchData = async (proid) => {
    try {
      const response = await axios
        .get(
          `
            https://utkrishtsociety.com/api/me?action=getuserinfo&memberid=${proid}`
        )
        .then((res) => {
          setData(res.data.user_Data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData(proid.id);
  }, []);
  return (
    <>
      <NavBar name="Profile Details" />

      {data.map((datas, ind) => {
        return (
          <Paper
            elevation={3}
            key={ind}
            style={{
              borderRadius: 10,
              margin: "10px",
              padding: "10px",
            }}
          >
            <Paper
              style={{
                border: "1px solid primary",
                borderRadius: "17px",
                margin: "5px 0",
              }}
            >
              {/* <Typography variant="h4" align="center" color="primary">
                        {datas.txtcustomerName}
                      </Typography> */}
            </Paper>
            <Stack spacing={2}>
              {/* <Item> SPONSOR ID : {datas.id}</Item> */}
              <Item> MAMBER NAME : {datas.txtcustomerName}</Item>
              <Item> MAMBER ID : {datas.id}</Item>
              <Item> MOBILE NO. : {datas.txtMobile}</Item>
              {/* <Item> MY RANK. : {datas.agent_post}</Item> */}
              <Item> STATUS : {datas.status}</Item>
              <Button
                fullWidth
                variant="contained"
                style={{
                  bgColor: "primary",
                  color: "#fff",
                  fontSize: "20px",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              {/* </Item> */}
            </Stack>
          </Paper>
        );
      })}
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};
export const Help = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);
  const proid = useParams();

  // const fetchData = async (proid) => {
  //   try {
  //     const response = await axios
  //       .get(
  //         `
  //           https://utkrishtsociety.com/api/me?action=getuserinfo&memberid=${proid}`
  //       )
  //       .then((res) => {
  //         setData(res.data.user_Data);
  //         setLoading(true);
  //       });
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData(proid.id);
  // }, []);
  return (
    <>
      <NavBar name="Help Desk" />
      <Box
        style={{
          display: "flex",
          margin: "15px",
          marginTop: '40px',

          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography color="primary">CENTRALIZED CUSTOMER DESK NO</Typography>
        <PhoneIphoneIcon   color="primary"
            style={{
              marginTop:"10px",
              border: "1px solid #39AFEA",
              padding: "5px",
              borderRadius: "18px",
            }}/>
        <Typography variant="body2" style={{ padding: "5px" }}>
          09399865229
        </Typography>
        <Typography variant="body2" >
          0755-4163306
        </Typography>
        <Box
          style={{
            borderRadius: "15px",
            marginTop:"10px",
          }}
        >
          <MailIcon
            color="primary"
            style={{
              border: "1px solid #39AFEA",
              padding: "5px",
              borderRadius: "18px",
            }}
          />
        </Box>
        <Typography variant="body2">utkrishtsociety99@gmail.com</Typography>
        <Box
          style={{
            borderRadius: "15px",
            marginTop:"10px",
          }}
        >
          <LanguageIcon
            color="primary"
            style={{
              border: "1px solid #39AFEA",
              padding: "5px",
              borderRadius: "18px",
            }}
          />
        </Box>
        
        {/* <a href="www.utkrishtsociety.in" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}> */}
        <Typography variant="body2" >www.utkrishtsociety.in</Typography>
        {/* </a> */}
        <Typography
          color="primary"
          style={{ margin: "23px", fontSize: "18px" }}
        >
          The normal working hours from Monday to Saturday are 10:00 am to 05:00 pm
        </Typography>
        <Typography style={{fontWeight: '700'}}>Get In Touch With Us</Typography>
        <Typography>Head Office</Typography>
        <Typography style={{textTransform: 'capitalize', width: '75%'}}>Shop no-20, patel market vidisha road bhanpur bhopal (M.P.) 462037</Typography>
      </Box>
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};
export const SavingAccountDetails = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);
  const proid = useParams();
  const fetchData = async (proid) => {
    try {
      const response = await axios
        .get(
          `
              https://utkrishtsociety.com/api/me?action=getuserinfo&memberid=${proid}`
        )
        .then((res) => {
          setData(res.data.user_Data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData(proid.id);
  }, []);
  return (
    <>
      <NavBar name="Account Details" />

      {data.map((datas, ind) => {
        return (
          <Paper
            elevation={3}
            key={ind}
            style={{
              borderRadius: 10,
              margin: "10px",
              padding: "10px",
            }}
          >
            <Paper
              style={{
                border: "1px solid primary",
                borderRadius: "17px",
                margin: "5px 0",
              }}
            >
              {/* <Typography variant="h4" align="center" color="primary">
                          {datas.txtcustomerName}
                        </Typography> */}
            </Paper>
            <Stack spacing={2}>
              {/* <Item> SPONSOR ID : {datas.id}</Item> */}
              <Item> ACCOUNT HOLDER NAME : {datas.txtcustomerName}</Item>
              <Item> USER NAME : {datas.id}</Item>
              <Item> ACCOUNT NUMBER : {datas.txtAccountNo}</Item>
              <Item> BANK NAME : {datas.txtbankname}</Item>
              <Item> BRANCH NAME : {datas.txtBranchName}</Item>
              <Item> IFSC CODE : {datas.txtIfsc}</Item>
              <Button
                fullWidth
                variant="contained"
                style={{
                  bgColor: "primary",
                  color: "#fff",
                  fontSize: "20px",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              {/* </Item> */}
            </Stack>
          </Paper>
        );
      })}
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </>
  );
};
