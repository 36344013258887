import {
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import FooterNav from "./FooterNav";
import { useEffect, useState } from "react";
import axios from "axios";
import {  NavLink, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "./Navbar";

const Loan = () => {
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);
  const navigate = useNavigate()
  const fetchData = async () => {
    const mamberID = localStorage.getItem("mamberId");
    try {
      const response = await axios
        .get(
          `https://utkrishtsociety.com/api/function?action=loan&memberid=${mamberID}`
        )
        .then((res) => {
          setData(res.data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

const handleClick= ()=>{
  navigate('/statement')
}

  return (
    <div id="app" data-v-app="" style={{ fontSize: "12px" }}>
      <NavBar name =' Loan Policy'/>
 
    {loding === false ? (
      <Box
        sx={{
          width: "100%",
          marginTop: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    ) : (
      <>
        {data.length > 0 ? (
          <div>
            {data.map((datas, ind) => {
              return (
                <Paper
                  elevation={3}
                  key={ind}
                  style={{
                    borderRadius: 10,
                    margin: "10px",
                    padding: "10px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems:"center"
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "serif", fontSize: "16px" }}
                    >
                      Policy no.- <span>{datas.id}</span>{" "}
                    </Typography>
                    <Typography
                      style={{ fontFamily: "serif", fontSize: "16px" }}
                    >
                      Date <span>{datas.txtpDate}</span>
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems:"center"
                      // margin: "15px",
                    }}
                  >
                    <Button>Balance</Button>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems:"center"
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "serif" }}
                    >
                      Maturity Date <span>{datas.txtMdate}</span>
                    </Typography>
                    <NavLink style={{textDecoration: "none"}} to={`/statement/${datas.id}`} >
                      <Button color="primary">Statements</Button>
                      </NavLink>  
                  </Box>
                </Paper>
              );
            })}
          </div>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              height: "200px",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "serif",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              Record Not Found
            </p>
          </Box>
        )}
      </>
    )}
<Box style={{marginTop:'60px'}}>
<FooterNav />
</Box> 
  </div>
  );
};
export default Loan;
