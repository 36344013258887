import AppBar from "@mui/material/AppBar";
import theme from "../theme";
// import './login.css'
import HomeIcon from '@mui/icons-material/Home';
import { Box, Typography } from "@mui/material";
import { useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NavBar =(props)=>{
  let navigate = useNavigate();

    return(
        <>
            <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            {/* <Link to="/"> */}
              <Box
                style={{ borderColor: "#fff", color: "#fff" }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </Box>
            {/* </Link> */}
            <Typography variant="h6" style={{ marginRight: '-30px'}}  color={theme.palette.primary.light}>
            {props.name}
            </Typography>
            <img src="/images/logo10.png" width="70px" alt="" />
          </AppBar>
        </Box>
        </>
    )
}
export default NavBar;