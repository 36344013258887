import DDS from "./component/DDS";
import FD from "./component/FD";
import HomePage from "./component/HomePage";
import LoginView from "./component/Login";
import { HashRouter, Route, Routes } from "react-router-dom";
import Loan from "./component/Loan";
import Statement from "./component/Statement";
import RD from "./component/RD";
import FDDetails from "./component/FDDetails";
import Details from "./component/Details";
import MyDetails, { Help, Profile, SavingAccountDetails } from "./component/MyDetails";
import ChangePass from "./component/ChangePass";
import ForgetPass from "./component/ForgetPass";


function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/login" element={<LoginView />} />
          <Route exact path="/dds" element={<DDS />} />
          <Route exact path="/forgetpass" element={<ForgetPass />} />
          <Route exact path="/fd" element={<FD />} />
          <Route exact path="/rd" element={<RD />} />
          <Route exact path="/loan" element={<Loan />} />
          <Route exact path="/changepassword/:id" element={<ChangePass />} />
          <Route exact path="/help" element={<Help />} />
          <Route path='/statement/:id' element= {<Statement />} />
          <Route exact path="/details/:id" element={<FDDetails />} />
          <Route exact path="/policydetails/:id" element={<Details />} />
          <Route exact path="/my_details/:id" element={<MyDetails />} />
          <Route exact path="/profile/:id" element={<Profile />} />
          <Route exact path="/account_detatils/:id" element={<SavingAccountDetails />} />

        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
