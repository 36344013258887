import {
  Box,
  Paper,
  Typography,
} from "@mui/material";
import FooterNav from "./FooterNav";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "./Navbar";

const Statement = () => {
  const [data, setData] = useState([]);
  const [loding, setLoading] = useState(false);
  const proid = useParams();
  const fetchData = async (proid) => {
    // const mamberID = localStorage.getItem("mamberId");
    try {
      const response = await axios
        .get(
          `https://utkrishtsociety.com/api/function?action=policystatement&policyno=${proid}`
        )
        .then((res) => {
          setData(res.data);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    fetchData(proid.id);
  }, []);
  return (
    <div id="app" data-v-app="" style={{ fontSize: "12px" }}>
     <NavBar name ='Statement'/>
      {loding === false ? (
      <Box
        sx={{
          width: "100%",
          marginTop: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    ) : (
      <>
      {data.length > 0 ? (
        <div>
          {data.map((datas, ind) => {
            return (
              <Box
               
                key={ind}
                style={{ borderBottom:"1px solid #7c7575", marginTop: "10px", padding: "10px" }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography fontSize={14} style={{color: '#066ca0'}} >
                    Policy No:<span>{datas.policyno}</span>{" "}
                  </Typography>
                  <Typography fontSize={14}>
                    Inst. deposit Date <span>{datas.InstDepositDate}</span>
                  </Typography>
                </Box>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography fontSize={14}>
                    Inst. Amt <span>{datas.InstAmt}</span>
                  </Typography>
                  <Typography fontSize={14}>
                   Current deposit Amt <span>{datas.InstAmt * datas.txtNoOfInst}</span>
                  </Typography>
                  <Typography fontSize={14} style={{color: '#066ca0'}}>
                    No Of Inst <span>{datas.txtNoOfInst}</span>
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </div>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            height: "200px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontFamily: "serif",
              fontSize: "16px",
              fontStyle: "italic",
            }}
          >
            Record Not Found
          </p>
        </Box>
      )}
      </>
    )
    }
<Box style={{marginTop:'60px'}}>
<FooterNav />
</Box> 
    </div>
  );
};
export default Statement;
