import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import FooterNav from "./FooterNav";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "./Navbar";
// import Paper from '@mui/material/Paper';
// import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export function formatDate2(dateString) {
  let date;

  // Check if the dateString includes '-' indicating DD-MM-YYYY format
  if (dateString.includes('-') && dateString.split('-').length === 3) {
      const parts = dateString.split('-');
      // Convert DD-MM-YYYY to YYYY-MM-DD for Date parsing
      date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
  } else {
      // Assume YYYY-MM-DD format
      date = new Date(dateString);
  }

  if (isNaN(date.getTime())) {
      return 'Invalid Date';
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
const FDDetails = (props) => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataAmount, setDataAmount] = useState([]);
  const [dataAgent, setDataAgent] = useState();
  const [loding, setLoading] = useState(false);
  const proid = useParams();
 

  const fetchData = async (proid) => {
    try {
      const response = await axios
        .get(
          `https://utkrishtsociety.com/api/function?action=policyinfonew&policyno=${proid}`
        )
        .then((res) => {
          setData(res.data.policy_data);
          console.log("loding", res.data.policy_data);
          setDataAmount(res.data.totalpolicybalance);
          setDataAgent(res.data.agent_name_new);
          setLoading(true);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };






  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
  useEffect(() => {
    fetchData(proid.id);
  }, []);

  return (
    <div id="app" data-v-app="" style={{ fontSize: "12px" }}>
      <NavBar name="Details" />
      {loding === false ? (
        <Box
          sx={{
            width: "100%",
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {data.length > 0 ? (
            <div>
              {data.map((datas, ind) => {
                return (
                  <Paper
                    elevation={3}
                    key={ind}
                    style={{
                      borderRadius: 10,
                      margin: "10px",
                      padding: "10px",
                    }}
                  >
                    <Paper
                      style={{
                        border: "1px solid primary",
                        borderRadius: "17px",
                        margin: "5px 0",
                      }}
                    >
                      <Typography variant="h4" align="center" color="primary">
                        {datas.txtcustomername}
                      </Typography>
                    </Paper>
                    <Stack spacing={2}>
                      <Item>MAMBER ID : {datas.MemberID}</Item>
                      <Item> POLICY NO. : {datas.id}</Item>
                      <Item> POLICY NAME. : {datas.DrpRDplan}</Item>
                      <Item> POLICY AMOUNT : {datas.txtinstAmount}</Item>
                    
                      <Item> MATURITY AMOUNT : {datas.txtMAmt}</Item>
                      <Item> POLICY DATE : {formatDate(datas.txtpDate)}</Item>
                      <Item> MATURITY DATE : { formatDate2(datas.txtMdate)}</Item>
                      <Item> NOMINEE NAME : {datas.txtNName}</Item>
                      <Item> NOMINEE RELATION : {datas.txtMRelation}</Item>
                      <Item> AGENT ID : {datas.txtStartagent}</Item>
                      <Item> AGENT NAME : {dataAgent}</Item>

                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          bgColor: "primary",
                          color: "#fff",
                          fontSize: "20px",
                        }}
                        onClick={() => navigate(-1)}

                      >
                        Back
                      </Button>
                      {/* </Item> */}
                    </Stack>
                  </Paper>
                );
              })}
            </div>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                height: "200px",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "serif",
                  fontSize: "16px",
                  fontStyle: "italic",
                }}
              >
                Record Not Found
              </p>
            </Box>
          )}
        </>
      )}
      <Box style={{ marginTop: "60px" }}>
        <FooterNav />
      </Box>
    </div>
  );
};
export default FDDetails;


